.color-picker {
  .popover {
    position: absolute;
    z-index: 2;
  }

  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .swatch {
    padding: $pm-size-small;
    border-radius: $radius;
    border: 2px solid $grey-lighter;
    display: inline-block;
    cursor: pointer;
  }

  .color {
    width: 36px;
    height: 14px;
    border-radius: $radius;
  }
}
