@import "components/Menu/Menu";
@import "components/Alerts/Alerts";
@import "components/Layout/Layout";
@import "components/PageHeader/PageHeader";
@import "components/PageLoader/PageLoader";
@import "components/Modal/Modal";
@import "components/Table/Table";
@import "components/FormInput/FormInput";
@import "components/Confirm/Confirm.scss";
@import "components/ImageUploader/ImageUploader.scss";
@import "components/DatePicker/DatePicker.scss";
@import "components/Tabs/Tabs.scss";
@import "components/ColorPicker/ColorPicker.scss";
@import "components/MultilingualInput/MultilingualInput.scss";
@import "pages/SignIn/SignIn.scss";
@import "pages/SignUp/SignUp.scss";


/* css 05/07 */

.map-dimensions{
    position: relative;
    background: $dark;
    color: white;
    float: right;
    padding: 3px 8px;
    border-radius: 20px;
    font-size: 12px;
    margin-top: -45px;
    margin-right: 8px;
}