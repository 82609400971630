.is-danger .react-select__control {
  border-color: $danger;
}

.submit-box{
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.field.is-horizontal {
  .label {
    margin-right: $pm-size-small;
    
  }
}