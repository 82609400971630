.tabs {
  margin-bottom: 0;

  .icon:first-child {
    margin-right: 0;
  }
  
  &.with-icons-only {
    padding-left: $pm-size-small;

    .tab {
      .icon {
        margin-right: .25rem;
      }
    }

    li {
      transition: all .3s;

      &.is-active {
        a {
          color: $primary;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: .125rem;
    }
    a {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.tabsWithContent {
  position: relative;
  margin-top: 40px;
  .tabs {
    margin-bottom:0;
    top:-40px;

    &.is-boxed + div > .tab-content {
      padding: $pm-size-small;
      border: 2px solid $grey-lighter;
      border-top: 0;
      border-radius: $radius;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-color: $light;
    }
  }

  .empty {
    opacity:0.4;
  }

  margin-bottom:0.75rem;
}