.seatsViewer {
    display: flex;
  }

.seatsTable {
    margin: 10px;
}

.tableHeader {
    text-align: center;
}