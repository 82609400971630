.watches {
  .row {
    margin-bottom: 15px;

    .col-button {
      align-self: flex-end;
      max-width: 40px;
      margin-right: 25px;
    }
    .col-field {
      flex: 1;
      align-self: center;
    }
  }
}