.title-filter{
  input{
    border-radius: $radius;
  }
}

.DayPicker{
  width: 100%;
  .DayPicker-Month{
    margin: 5px 0 0 0;
  }
  .DayPicker-Day{
    padding: 0.35em 0.5em;
  }

  .DayPicker-NavButton{
    top: 5px;
  }
}

.date-picker {
  &.date-picker-disabled{
    pointer-events: none;
    opacity: 0.6;
  }

  label {
    margin-bottom: $pm-size-small;
    display: block;
  }

.DayPickerInput {
  width: 100%;
  max-width: 400px;

  input{
    background: transparent;
    color: white;
    border-color: $dark-lighter;
    &:focus{
      border-color: $primary;
    }
  }

  .DayPickerInput-OverlayWrapper{
    z-index: 10;
    .DayPickerInput-Overlay{
      left: 0;
      right: auto;

      .DayPicker-Day{
        line-height: 35px;
        min-width: 35px;
        height: 35px;
        padding: 0;
        &--selected{
          color: white !important;
        }

        &:focus{
          outline: none;
        }
      }
    }
  }

}

.DayPicker {
  color: #1d1c26;
}
}


.morning{
  .DayPicker-Day--selected{
    background-image: linear-gradient(to right, #0061d2 50%, white 50%), radial-gradient(black 70%, white 70%);
    text-shadow: 1px 1px 0 black;
    background-blend-mode: lighten;
  }
}

.afternoon{
  .DayPicker-Day--selected{
    background-image: linear-gradient(to left, #0061d2 50%, white 50%), radial-gradient(black 70%, white 70%);
    text-shadow: 1px 1px 0 black;
    background-blend-mode: lighten;

  }
}
