.selector-panel {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > button{
    margin: 7px;
    max-width: calc(100% - 14px);
  }

  .actionsButton button{
    padding: 0;
    width: 36px;
    border-radius: 100%;
    background: white;
    float: right;
    color: cornflowerblue
  }

  &.contextual-panel {
    box-shadow: 2px 0 6px rgba(0,0,0,0.15);
    /*animation-name: enter;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-duration: 0.3s;*/

    position: absolute;
    top: 0;
    background: white;
    height: 100%;
    z-index: 1;
    width: 280px;
    padding-top: 50px;
    padding-bottom: 80px;

  

    .editor-head{
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 8px;

     
    }

    .editor-subtitle{
      padding: 8px;
      font-size: 17px;
      font-weight: bold;
      text-align: center;
    }

  }
}

.editor-edition{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  height: 50px;

  .editor-formatting{
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    padding: 0 20px;

    input{
      padding-right: 0;
      min-width: 70px;
    }
  }

  .editor-geometric{
    display: flex;
    justify-content: center;

    &:first-child{
      .beach-editor-menu--button:first-child{
       border-left: none;
      } 
    }

    button.beach-editor-menu--button {
      color: $dark;
      height: 30px;
      width: 30px;
      text-align: center;
      cursor: pointer;
      transition: 0.2s;
      border: 1px solid rgba(255,255,255,0.2);
      border-width: 0;
      background-color: transparent;
      border-left-width: 1px;
      color: white;

      &.delete-seats{
        .icon{
          width: auto;
          color: tomato;
        }
      }

    
    
    }
  }
  
  .editor-custom-field{
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    input{
      max-width: 50px;
      margin-left: 12px;
    }
  }
  

}


button.quit{
  color: #aaa;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  border: none;
  cursor: pointer;
}

.editor-form-control-horizontal {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  label {
    flex: 1;
    margin-left: 8px;
  }
}