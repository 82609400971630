.order-details {
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $pm-size;
  }
  
  &--footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: $pm-size;
  }

  &--meta {
    flex-grow: 1;
  }
  
  &--id {
    color: $dark;
    font-size: 1.2rem;
    font-weight: bold;
  }

  &--state {
    margin-left: $pm-size-small;
    flex-grow: 1.5;
  }

  &--total {
    font-weight: bold;
    display: flex;
    align-items: center;

    &-price {
      margin-left: $pm-size-small;
      font-size: 1.6rem;
      color: $primary;
    }
  }
}