html, body,#root {
  height:100%;
  width:100%;
}

h1.title {
  color: $primary;
}

h2.subtitle {
  color: $primary;
}

h2.formtitle{
  font-size: 25px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 10px;
  color: $primary;
}

a.button,
button.button,
input.button {
  border-radius: $radius-small;
  font-weight: bold;
  @include transition(all 0.3s ease);

  &.is-outlined {
    box-shadow: none;
  }

  &.is-small {
    border-radius: $radius;
    padding-left: $pm-size;
    padding-right: $pm-size;
  }
}

.input {
  border-radius: $radius-small;
  caret-color: $primary;
  @include transition(all 0.3s ease);
  &::placeholder {
    color: $dark;
  }

  &.has-button {
    height: calc(2.25rem + 4px);
  }
}

.table-link {
  color: $primary;
  &:hover {
    color: darken($primary, 10);
    text-decoration: underline;
    cursor: pointer;
  }
}

.card {
  border-radius: $radius;
}

.lead {
  font-weight: 700;
}

.border-radius {
  border-radius: $radius;
}

.navbar-item {
  @include transition(all 0.3s);
}

section.section {
  & + section{
    margin-top: 16px;
  }

  &.list-page, &.form-page {
    padding-top: 0;
    margin-top: -4rem;
  }

  section.section{
    padding: 0;
  }

  &.form-page {
    background-color: $white;
    border-radius: $radius;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding: 1.5rem;

  }
}

.table {
  td {
    display: flex;
    align-items: center;
    padding: .25rem .75rem !important;
  }
}

.link {
  color: $primary;
  cursor: pointer;

  &:hover {
    color: darken($primary, 10);
    text-decoration: underline;
  }
}

.headerlink {
  color: $primary;
  cursor: pointer;
  user-select: none;
  margin-right: 10px;

  &:hover {
    text-decoration: none;
  }
}


.modal-card,
.form-page,
.beach-sidebar,
thead,
tbody{
  .react-select__control{
    min-height: 36px;
  }

  .react-select__placeholder{
    white-space: nowrap;
    width: 100%;
  }

  .react-select__indicator{
    padding: 4px;
  }
}

.modal-card,
.form-page,
.beach-sidebar,
tbody{

  label.label{
    font-size: 0.9rem;
    font-weight: 600;
  }

  .input, .textarea, .select select, .react-select__control,
  .date-picker .DayPickerInput input{
    border-color: $grey-darker;
    color: $dark;
    &:hover{
      border-color: $grey-darker;
    }

    &--is-focused,
    &:focus,
    &:hover:focus{
      border-color: $primary;
    }
  }


 
}

.row{
  display: flex;
  & > *{
    flex: 1;
    & + *{
      margin-left: 12px;
    }
  }
}


.is-checkradio[type="checkbox"] + label::after, .is-checkradio[type="checkbox"] + label:after {
  top: 0.35rem;
}

.check-status-bar .is-checkradio[type="checkbox"] + label:before {
  border: 0.1rem solid white;
}

.check-status-bar  .is-checkradio[type="checkbox"]:checked + label:before {
  border: 0.1rem solid white;
  background: white;
}

.file {
  border: 1px dashed #aaa;
  cursor: pointer;
  padding: 10px;
}

.error {
  margin-top: 10px;
  max-height: 300px;
  overflow-x: auto;
  background-color: #ddd;
}

.error p {
  padding: 5px 30px !important;
  font-size: 0.9em;
  color: red;
  border-bottom: 1px solid #aaa;
}

.error p span {
  color: #000;
}