// Custom variables

// Sizings
$pm-size-small: 0.5rem;
$pm-size: 1rem;
$pm-size-medium: 2rem;
$pm-size-large: 3rem;

$gap-size: 1rem;
$gap-size-small: 0.5rem;
$gap-size-large: 2rem;

$radius: 10px;
$radius-small: 4px;
$radius-round: 290486px;

/** 
 * Bulma initial variables override
**/

// Fonts
$family-sans-serif: 'Lato', sans-serif;

// Colors
$primary: #009ad4;
$secondary: #fdd187;
$secondary-dark: #ffab41;
$danger: #f36954;
$success: #17b777;
$white: #fff;
$black: #000;
$light: #f4f4f4;
$dark: #1d1c26;
$dark-light: #282735;
$dark-lighter: #4c4b5c;
$info: #60a3bc;
$link: #4a69bd;
$warning: #f6b93b;
$grey: #aaa;
$light-blue:#DDF;
$grey-lighter: #ecf0f1;
$grey-darker: #bdc3c7;

$gradient-vertical: linear-gradient(180deg, rgba(255,172,67,1) 0%, rgba(252,215,147,1) 100%);
$gradient-horizontal: linear-gradient(90deg, rgba(255,216,83,1) 0%, rgba(255,151,21,1) 100%);
$gradient-horizontal-light: linear-gradient(90deg, rgb(249, 218, 116) 0%, rgb(253, 178, 87) 100%);

$gradient-horizontal-dark: linear-gradient(90deg, rgba(219,186,71,1) 0%, rgba(209,123,16,1) 100%);
$gradient-red: linear-gradient(90deg, rgba(255,167,136,1) 0%, rgba(255,83,83,1) 100%);
$gradient-green: linear-gradient(270deg, rgba(94,247,146,1) 0%, rgba(32,194,255,1) 100%);

/** 
 * Bulma components variables override
**/

$checkbox-border: .1rem solid $grey;

$body-overflow-y: auto;
$section-padding: 1.5rem;

$text: $dark;
$text-light: $light;

$message-radius: $radius-small;
$message-body-radius: $radius-small;
$message-body-padding: 1rem 1.25rem;

$notification-radius: $radius;
$notification-padding: 1rem 2rem 1rem 1.5rem;

// Forms
$control-border-width: 1px;
$input-background-color: $white;
$input-border-color: $grey-darker;
$input-hover-border-color: $dark;
$input-focus-color: $primary;
$input-color: $dark;
$input-shadow: none;
$input-focus-border-color: $primary;
$input-icon-active-color: $primary;
$input-focus-box-shadow-size: none;
$link-visited: $primary;
$input-radius: $radius-small;
$link-hover: $primary;
$label-color: $dark;
$label-weight: 400;

// Tabs
$tabs-border-bottom-width: 2px;
$tabs-border-bottom-color: $grey-lighter;
$tabs-link-hover-border-bottom-color: $grey-darker;
$tabs-link-active-border-bottom-color: $primary;
$tabs-link-color: $dark;
$tabs-link-hover-color: $dark;
$tabs-link-active-color: $light;
$tabs-boxed-link-hover-background-color: $grey-lighter;
$tabs-boxed-link-active-background-color: $primary;

// Cards
$card-header-shadow: none;
$card-content-padding: $pm-size;

// Buttons
$button-padding-horizontal: 1.6rem;

// Menu
$menu-size: 300px;
$menu-background-color: $dark;
$menu-item-color: $light;
$menu-label-color: $light;
$menu-item-hover-background-color: $secondary;
$menu-item-hover-color: $dark;

// Navbar
$navbar-background-color: $secondary;

// Tables
$table-color: $dark;
$table-head-cell-color: $light;
$table-head-background-color: lighten($dark, 5);
$table-striped-row-even-background-color: $light;

$new-colors: (
  'white': $white,
  'black': $black,
  'light': $light,
  'dark': $dark,
  'primary': $primary,
  'danger': $danger,
  'warning': $warning,
  'success': $success,
  'info': $info,
  'link': $link,
);
