.page-header {
  background: linear-gradient(90deg, $secondary-dark 0%, $secondary 100%);
  padding: 1.5rem;
  padding-bottom: 5.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
  position: relative;

  .sublink a {
    font-size: 0.80rem;
    color: $light;
  }

  & > *{
    position: relative;
    z-index: 1;
  }

  & + *{
    position: relative;
  }

  &--title.title {
    color: $light;
    margin-bottom: 0;
  }

  &--title-area {
    flex:1;
  }

  &--link {
    display: flex;
    align-items: center;
    margin-right: 10px;
    &-label {
      display: inline;
      color: $light;
      margin-right: 1rem;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
    }

    &-button.button {
      border-radius: 50%;
      padding: 0;
      font-size: 16px;
      height: 40px;
      width: 40px;
      color: $grey;
      box-shadow: 1px 1px 2px rgba(0,0,0,0.15);
      
      &:hover {
        color: $primary;
      }
    }
  }
}