.date-controller{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    &button{
        margin-left: 2px;
        margin-right: 2px;
    }
}

.date-nav{
    margin-left: 4px;
    margin-right: 4px;
    padding: 0 8px;
    border: none;
    color: white;
    background-color: transparent;
    cursor: pointer;
    font-size: 20px;
    height: 36px;

    &.is-disabled{
        color: white;
        background-color: transparent;
        *{
            opacity: 1;
            visibility: visible;
            color: white;
            background-color: transparent;
        }
    }
}


.date-title{
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

