.modal {
  &-card {
    border-radius: $radius;
    &-body {
      padding: $pm-size-medium;
      background-color: $white;
      display: flex;
      flex-direction: column;
    
      p {
        padding-bottom: $pm-size-medium;
      }
      
      .buttons {
        align-self: flex-end;
      }
    }
  }
}