
.list-action{
  th:last-child{
    display: flex;
    justify-content: flex-end;
    padding-right: 26px;
  }
  
}

.has-overflow-x-table .table{
  background: white;

  thead{
    z-index: 3;
  }
  th{
    background-color: $dark;
  }

  tr:nth-child(odd) td{
    background: $white;
  }

  tr:nth-child(odd):hover td,
  tr:nth-child(even):hover td{
    background:$light-blue;
  }

  tr:nth-child(even) td{
    background: $light;
  }

  tr > td,
  tr > th{
    border-radius: 0;
    word-break: break-word;
    &:first-child{
      left: 0;
      box-shadow: 1px 0 2px rgba(0,0,0,0.05);
      border-right: 2px solid rgba(0,0,0,0.05);
    }

    &:last-child{
    
      right: 0;
      box-shadow: -1px 0 2px rgba(0,0,0,0.05);
      border-left: 2px solid rgba(0,0,0,0.05);
    }

    &:first-child,
    &:last-child{
      position: sticky;
      min-width: 100px;
      z-index: 2;
    }

    &:not(:first-child):not(:last-child){
      min-width: 150px;
    }
  }
}

.table {
  display: block;
  background: transparent;

  &:not(.overflow-visible){
    max-height: calc(100vh - 180px);
    overflow: auto;
  }


  &::-webkit-scrollbar-track {
    background-color:rgba(0,0,0,0.1);
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color:rgba(0,0,0,0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.3);
    width: 3px;
    height: 5px;
  }


  thead,
  tbody{
    display: block;
  }

  thead{
    position: sticky;
    top: 0;
    z-index: 1; 
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }

  tbody{
   border-bottom: 1px solid #dddddd;
   min-height: 300px;

    tr:nth-child(odd):hover,tr:nth-child(even):hover {
      background-color:$light-blue !important;
    }
  }

  tr{
    display: flex;
    th, td{
      flex: 1;
    }
  }

  th {
    padding: .75rem;
    font-weight: normal;
    display: flex;
    align-items: center;
    height: 64px;
    border: none;

    &.th-day{
      justify-content: center;
    }

    & > *:not(.th-label){
      flex: 1;
    }

    &:first-child {
      border-top-left-radius: $radius;
    }

    &:last-child {
      border-top-right-radius: $radius;
      justify-content: flex-end;
      text-align: right;
    }

    span {
      display: block;
    }
  }
  td {
    vertical-align: middle;
    padding: .75rem;
  }
}
.table-actions {
  display: flex;
  justify-content: flex-end;
  button {
    padding-left: 1rem;
    padding-right: 1rem;

    &:first-child {
      padding-left: 0;
    }
  }
}

.pagination {
  &-link {
    &:focus {
      border-color: $primary;
    }
    &.is-current {
      background-color: $primary;
      border-color: $primary;
    }
  }
}

input.input.is-filter {
  background-color: lighten($dark, 5);
  border-color: $dark-lighter;
  color: $light;

  &:focus{
    border-color: $primary;
  }

  &::placeholder {
    color: $light;
  }
}


.pagination-link{
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  color: #636b70;

  &:focus{
    box-shadow: none;
  }
}

.pagination-results-counter{
  margin-right: 20px;
  padding-right: 30px;
  border-right: 1px solid #ddd;
}
