.register-page {
  width: 40%;
  margin: 0 auto;
}

.logo {
  margin: 0 auto;
  width: 300px;
  height: 300px;
  background-size: cover;
}