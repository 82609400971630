.booking-modal {
  p {padding: 0;}

  &--header {
    padding-bottom: 2rem;
    position: relative;
  }

  &--title {
    font-size: 2rem;
    display: flex;
    align-items: center;
  }

  &--content {
    display: flex;
  }

  &--footer {
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .button{
      margin: 4px;
    }
  }

  &--details {
    flex: 1;
  }

  &--activity {
    flex: 1;
    border-left: 1px solid #ddd;
    padding: 0 $pm-size;

    &-header {
      border-bottom: 1px solid #ddd;
      padding-bottom: 1rem;
    }
  }

  &--modify{
    border-radius: 50%;
    padding: 0;
    font-size: 16px;
    height: 40px;
    width: 40px;
    color: $light;
    box-shadow: 1px 1px 2px $grey;
    border: none;
    background-color: $primary;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  &--orders{
    &-title{
      margin-top: 1rem;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  &--client {
    display: flex;
    justify-content: flex-start;
    &-info {
      margin-right: 1rem;
      display: flex;
      align-items: center;
    }
  }

  &--seat {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: $pm-size-small;

    &-image {
      position: relative;
      background: $gradient-vertical;
      width: 4rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $radius-small;
      overflow: hidden;

      img {
        width: 80%;
        height: auto;
      }
    }

    &-name {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #00000090;
      color: $light;
      padding: 0.125rem 0.25rem;
    }
  }

  &--code {
    font-size: 2rem;
  }

  &--customer {
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
  }

  &--order {
    &-text {
      font-weight: bold;
    }
    &-items {
      overflow-y: scroll;
      height: 10rem;
    }
    &-item {
      height: 4rem;
      background-color: $light;
      border-radius: $radius;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      box-shadow: 0 1px 2px $grey;

      &:not(:last-child) {
        margin: 0.5rem 0;
      }

      &--image {
        position: relative;
        background: $gradient-vertical;
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex: 1;
        overflow: hidden;
        width: 100px;
        height: 100px;
        background-position: center center;
        background-size: cover;
      }

      &--content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 4;
        padding: 0 $pm-size-small;
      }

      &--meta {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &--name {
        font-weight: bold;
        font-size: .9rem;
        margin-bottom: .25rem;
      }

      &--price {
        font-weight: bold;
        display: flex;
        align-items: baseline;

        &-amount {
          font-size: 1.5rem;
          margin-right: 0.125rem;
        }
        &-currency {
          font-size: 1.25rem;  
        }
      }

      &--quantity {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $gradient-horizontal;
        padding: 0 1rem;
        border-radius: $radius-round;
        color: $light;
        width: 3rem;
      }
    }
  }

  &--waiter {
    display: flex;
    justify-content: space-between;
    &-last-call {
      display: flex;
      align-items: center;
      &--dot {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin-right: $pm-size-small;

        &.is-primary {
          background-color: $primary;
        }
        &.is-warning {
          background-color: $warning;
        }
        &.is-danger {
          background-color: $danger;
        }
      }
    }
  }
}