// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,600,700');

@import 'styles/all';

// External frameworks
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

// Components
@import './common/common';
@import './users/users';
@import './orders/orders';
@import './sites/sites';
@import './products/products';
@import './pos/pos';
@import './maps/maps';
@import './watches/watches';