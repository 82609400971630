 main {
  transition: margin-left .5s;
  margin-left: 0;
  padding-top: 0;
  width:100%;
  height:100%;

  &.menu-open {
    margin-left: $menu-size;
    width: calc(100% - #{$menu-size});
  }
}