.order-state-tag {
  background: $gradient-red;
  color: $light;
  padding: $pm-size-small;
  border-radius: $radius-round;
  display: flex;
  line-height: 0;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  &.is-done {
    background: $gradient-green;
  }

  .fas {
    margin-right: $pm-size-small;
    margin-bottom: -2px;
  }
}