.map-cards{
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    .map-card-item {
        min-width: calc(50% - 16px);
        max-width: calc(50% - 16px);
        background-color: white;
        margin-bottom: 32px;
        box-shadow: 0 2px 2px rgba(0,0,0,0.15);

        &.map-card-item-new {
            height: 250px;
            border: 2px dashed #dddddd;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: none;
            font-size: 40px;
            cursor: pointer;
            color: rgb(162, 162, 162);
            transition: 0.3s;
            &:hover{
                background-color: #eaeaea;
            }

        }
        
        &:nth-child(2n + 1){
            margin-right: 16px;
        }
        &:nth-child(2n){
            margin-left: 16px;
        }

        .map-card-background {
            width: 100%;
            height: 200px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-color: cornsilk;
            cursor: pointer;
        }

        .map-card-bottom {
            display: flex;
            align-items: center;
            padding: 8px;

            .map-card-bottom-left{
                flex: 1;
                font-weight: bold;
            }

            .map-card-bottom-right{
                button.button {
                    padding: 0 8px;
                    margin-left: 8px;
                    width: 36px;
                    height: 36px;
                    border-radius: 100%;
                    border: 1px solid #ddd;
                }
            }
        }
    
    }
}

