.alerts {
  position: fixed;
  left: 45%;
  z-index: 10000;
}

.alert-enter {
  opacity: 0;
}
.alert-enter-active {
  opacity: 1;
  transition: opacity 600ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}