.beach-organizer {
  position:relative;
  height:100%;
  overflow:hidden;
  display:flex;
  padding-top: 50px;
}

.beach-editor {
  flex:1;
  position:relative;
  overflow:hidden;
  background-color: #555;
  cursor: no-drop;
  box-shadow: 4px 0 6px inset rgba(0, 0, 0, 0.3);
}

.beach-offset-screen{
  width: 100%;
  height: 100%;
}

.beach-visualizer {
  background-color:  #fbeeb5;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-attachment: local;
  background-position: center;
  background-size:100%;
  height:100%;
  width:100%;
  position:relative;
  transform-origin: top left;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
}


.beach-header {
    position: absolute;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    background: cornflowerblue;
    height: 50px;
    padding: 0 16px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    z-index: 3;


    h1 {
      flex-grow:1;
      font-weight: 600;
      line-height: 1.125;
    }
}

.beach-mode.buttons {
  flex-grow:1;
  margin-bottom:0;
}

.beach-sidebar {
  width:280px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  & > *{
    width: 100%;
  }
}

.beach-footer{
  position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 3;
    background-color: $dark;
    color: white;
    box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.15);

    input, select{
      border-radius: 4px;
      height: 36px;
      padding: 0 8px;
      line-height: 36px;
      font-size: 14px;
    }

}

.board {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.grid {
  margin: auto;
  width: 80%;
  padding: $pm-size;
  position:relative;
}

.grid:after {
  content:"";
  display:block;
  padding-bottom:100%;
}
.grid .content {
  width:100%;
  height:100%;
  position:absolute;
}

.BoardSquare {
  position: relative;
  width: 100%;
  height: 100%;
}

.BoardItem {
  position:relative;

  .seatName {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width:90%;
  }
}

.seatPrice {
  background: transparent linear-gradient(270deg, #FF5353 0%, #FFA788 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  bottom: 5px;
  left: 0;
  font-size: 13px;
  /*width: 35px;*/
  min-width: 35px;
  height: 28px;
  font-weight: 600;
  letter-spacing: -1px;
  color: #FFFFFF;
  display: block;
  padding: 0 5px;
  border-radius: 8px;
  line-height: 28px;
  text-align: center;
}

.mouse-select{
  cursor: pointer;
} 

.mouse-move {
  cursor: move;
}

.Square {
  color: $danger;
  width: 100%;
  height: 100%;
  border:1px dashed #E5D695;
}

//-----------------------------------


.selectors {
  display:flex;
  flex-direction: column;
  height: calc(100% - 220px);
  overflow-x: hidden;
  overflow-y: auto;
  width:100%;
  padding: 8px;
}

.selector {
  min-height: 70px;
  width: 100%;
  cursor: pointer;
  transition: all .3s ease;
  display: flex;
  align-items: center;
  //border-bottom: 1px solid $grey-lighter;
  font-size: .8rem;
  padding: 0 $pm-size-small;
  position: relative;
  background-color: $grey-lighter;
  margin-bottom: 4px;
  border-radius: 5px;
  margin-left: 8;
  margin-right: 8px;
  transition: 0.3s;


  &button{
    border: none;
    
    &:hover {
      border: none;
    }
  }

  img {
    width:60px;
    margin-right: $pm-size;
  }

  .selector-icon {
    color: $danger;
    width: 60px;
    text-align: center;
    font-size: 24px;
  }

  &:hover {
    border-color: $grey-darker;
  }

  .circleSelector {
    box-shadow: 0 1px 2px rgba(0,0,0,0.4);
    border: 2px solid white;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    &Wrapper {
      width: 60px;
      height: 60px;
      margin-right: $pm-size;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    width: 40px;
    height: 40px;
    border-radius: $radius-round;
  }

  .selectorName {
    flex:1;
    font-size: 15px;
    line-height: 17px;
    padding: 8px 0;
    font-weight: 700;

    p{
      font-style: italic;
      opacity: 0.8;
      font-size: 12px;
      line-height: 13px;
      font-weight: normal;
      margin-top: 4px;
    }
  }

  .actionsButton {
    width: 50px;
  }

  &:hover,
  &.selected {
    background: $gradient-horizontal-light;
    // transform: scale(1.2); ca ca fait planter la modal 
  }
}

button.minusColumnButton {
  padding: 0;
  font-size: 0.7rem;
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  top: -28px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: $radius-round;
  cursor:pointer;
}

button.minusRowButton {
  position: absolute;
  left: -30px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: $radius-round;
  padding: 0;
  font-size: 0.7rem;
  width: 1.2rem;
  height: 1.2rem;
  cursor:pointer;
}

.buttons.has-addons {
  button.button.beach-editor-tabs--button {
    background-color: transparent;
    border: none;
    color: $light;
    margin: 0;
    border-radius: $radius-round;
  
    &.is-active {
      //background: $gradient-horizontal;
      color: $light;
      background-color: rgba(255,255,255,0.3);
    }
  }
}


.gridTop {
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
  margin: auto;
  width: 260px;
  height: 50px;
  background: none;
    border: 1px dashed #333;
    border-radius: 10px;
    cursor:pointer;
}

.gridBottom {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: auto;
  width: 260px;
  height: 50px;
  z-index:4;
  background: none;
    border: 1px dashed #333;
    border-radius: 10px;
    cursor:pointer;
}

.gridLeft {
  position: absolute;
  bottom: 0px;
  left: -90px;
  top:0;
  margin: auto;
  width: 50px;
  height: 260px;
  background: none;
    border: 1px dashed #333;
    border-radius: 10px;
    cursor:pointer;
}

.gridRight {
  position: absolute;
  bottom: 0;
  top:0;
  right: -70px;
  margin: auto;
  width: 50px;
  height: 260px;
  margin: auto;
  background: none;
    border: 1px dashed #333;
    border-radius: 10px;
    cursor:pointer;
}


.BoardItem-dragging {
  opacity: 0.5;
}

.zoom{
  position: absolute;
  bottom: 55rem;
  right: 1.5rem;
  display: flex;
  flex-direction: revert;
  border-radius: 40px;
  overflow: hidden;

  button {
    background: rgba(0,0,0,0.8);
    color: white;
    border: 0;
    font-size: 24px;
    cursor: pointer;
    font-weight: bold;
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    & + button{
      border-left: 1px solid rgba(255,255,255,0.1);
    }
  }
}

.map-scale{
  position: absolute;
  bottom: 3rem;
  left: 1.5rem;
  display: flex;
  flex-direction: revert;
  border-radius: 40px;
  overflow: hidden;

  span {
    background: rgba(0, 0, 0, 0.8);
    color: white;
  }

  button {
    background: rgba(0,0,0,0.8);
    color: white;
    border: 0;
    font-size: 24px;
    cursor: pointer;
    font-weight: bold;
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    & + button{
      border-left: 1px solid rgba(255,255,255,0.1);
    }
  }
}


.mapInfo {
  position: absolute;
  bottom: 55px;
  left: 10px;
  background-color: #dedede;
  padding: 5px;
  font-size: 12px;
  border-radius: 10px;
}

.seatNumber{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: #11111190;
  color: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .25rem;
  font-weight: bold;
}

.editor-selected-zone{
  background-color: #b653fa;
  position: absolute;
  opacity: 0.4;
  z-index: -1;
  min-width: 50px;
  min-height: 50px;
  border: 5px dashed #0101ff;
  box-sizing: content-box;
  cursor: grab;
}

.editor-incrementation{
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editor-add-type{
  background: linear-gradient(90deg, #ffd853 0%, #ff9715 100%);
  border-radius: 15px;
  height: 30px;
  border: none;
  color: white;
  font-weight: bold;
  padding: 0 12px;
  font-size: 13px;
}
.editor-fields-bar{
  display: flex;
  align-items: center;

  .incrementation-item{
    & + .incrementation-item{
      margin-left: 8px;
    }

     input{
      width: 55px;
      font-size: 17px;
      border: none;
    }

    select{
      font-weight: bold;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0;
      border: none;

      &.colored{
        text-shadow: 0px 1px 7px $dark;
        color: white;
      }
  
      option{
        height: 30px;
        line-height: 30px;
        text-shadow: 0px 1px 7px $dark;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }




}

button.save-editor-button.button{
  min-width: 200px;
  font-size: 17px;
  padding: 0;
  border-radius: 30px;

  &.is-success{
    background-color: rgba(255,255,255,0.3);
  }
}

.editor-title-add{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  padding: 0 8px;

  b{
    flex: 1
  }
  button {
    margin-left: 12px;
  }
}

.editor-type-modal-buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: white;

  button.button.is-danger{
    margin-top: 15px;

    i{
      margin-right: 8px;
    }
  }

  * + *{margin-left: 16px;}
}