$fade-grey: #ededed;
$grey: #ccc;
$muted-grey: #999;
$heart: #ff4f8f;
$white: #fff;

//LOADER STYLES
.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background:rgba(255,255,255,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  z-index: 1;
  
  .loader {
    height: 150px;
    width: 150px;
    border: 5px solid $primary;
    border-right-color: transparent;
    border-top-color: transparent;
  }
}

.is-loading {
  position: relative;
}